import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Contattaci" />
		<main class="pagina-form-contatto">
    <h1>Questo è un form di contatto</h1>
    <p>Lasciaci un messaggio e la tua mail.</p>

		<form name="contact" method="POST" data-netlify="true" action="/grazie">
		{/* questo input nascosto va aggiunto quando si tratta di gatsby o di altro sito statico
			fonte: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators
		*/}
				<input type="hidden" name="form-name" value="contact" />
			<p>
				<label>Nome: <input type="text" name="nome" placeholder="Nome Cognome" /></label>
			</p>
			<p>
				<label>Email: <input type="email" name="email" placeholder="mail richiesta" /></label>
			</p>
			<p>
				<label>Messaggio: 
					<br />
					<textarea placeholder="Lascia qui il tuo messaggio con le richieste più assurde" name="messaggio"></textarea></label>
			</p>
			<p>
				<label>Giorno di arrivo: 
					<br />
					<input name="start" type="date" /> </label>
			</p>
			<p>
				<label>Giorno di partenza:
					<br />
					<input name="end" type="date" /> </label>
			</p>
			<p>
				<button type="submit">Send</button>
			</p>
		</form>
		</main>
  </Layout>
)